class Config {
    constructor() {
        if (Config.instance) {
            return Config.instance;
        }
        this.title = undefined;
        this.channelId = undefined;
        this.subtitle = undefined;
        this.popupBehavior = undefined;
        this.theme = undefined;
        this.language = undefined;
        this.history = undefined;
        this.userToken = localStorage.getItem('access_token');
        this.userId = localStorage.getItem('webChatUserId');
        if (!this.userId) {
            this.userId = this.generateUniqueUserId();
            localStorage.setItem('webChatUserId', this.userId);
        }
  
        this.trackingData = null;

        Config.instance = this;
    }

    static getInstance() {
        if (!Config.instance) {
            Config.instance = new Config();
        }
        return Config.instance;
    }

    checkEnvironment() {

        let errorMessage = null;
        this.channelId = window.webChatWidgetChannelId || process.env.REACT_APP_CHANNEL_ID;
        if (!this.channelId) {
            errorMessage = 'Error: Channel ID not provided';
        }

        this.title = window.webChatWidgetTitle || process.env.REACT_APP_WIDGET_TITLE;
        if (!this.title) {
            errorMessage = 'Error: Title not provided';
        }

        this.subtitle = window.webChatWidgetSubtitle;
        if (!this.subtitle) {
            this.subtitle = '';
        }

        this.popupBehavior = window.webChatWidgetPopBehavior || process.env.REACT_APP_WIDGET_POPUP_BEHAVIOR;
        if (!this.popupBehavior) {
            errorMessage = 'Error: Popup Behavior not provided';
        }

        this.theme = window.webChatWidgetTheme || process.env.REACT_APP_WIDGET_THEME;
        if (!this.theme) {
            errorMessage = 'Error: Theme not provided';
        }

        this.language = window.webChatWidgetLanguageId || process.env.REACT_APP_WIDGET_LANGUAGE;
        if (!this.language) {
            errorMessage = 'Error: Language not provided';
        }
        
        return errorMessage;
    }

    generateUniqueUserId() {
        return Math.random().toString(36);
    }

    getAllHistories() {
        try {
            const storedHistories = localStorage.getItem("webChatUserHistories");
            return storedHistories ? JSON.parse(storedHistories) : null;
        } catch (error) {
            console.error("Error retrieving message histories:", error);
            return null;
        }
    }
    getHistory() {
        const storedHistories = this.getAllHistories();
        return storedHistories ? storedHistories[this.channelId + this.userId] : null;
    }
    addToHistory(newMessage) {
        let storedHistories = this.getAllHistories() || {};
        let userHistory = storedHistories[this.channelId + this.userId] || [];
        userHistory.push(newMessage);
        storedHistories[this.channelId + this.userId] = userHistory;

        try {
            localStorage.setItem("webChatUserHistories", JSON.stringify(storedHistories));
        } catch (error) {
            console.error("Error storing message histories:", error);
        }
    }
    removeHistory() {
        localStorage.removeItem('webChatUserHistories');
    }
    setChatActiveStatus(status) {
        const key = `webChatActive${this.channelId}${this.userId}`;
        localStorage.setItem(key, status);
        window.isWebChatWidgetActive = status;
    }
    getChatActiveStatus() {
        const status = localStorage.getItem(`webChatActive${this.channelId}${this.userId}`);
        return status === 'true';
    }
    setChatMinimizedStatus(status) {
        const key = `webChatMinimized${this.channelId}${this.userId}`;
        localStorage.setItem(key, status);
    }
    getChatMinimizedStatus() {
        const status = localStorage.getItem(`webChatMinimized${this.channelId}${this.userId}`);
        return status === 'true';
    }
}

export default Config;
